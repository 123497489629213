import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent } from "@mui/material";
import CheckboxWrapper from "../../../../../../../Components/FormComponents/CheckboxWrapper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { editLeadSource } from "../../State/LeadsDuck";

export default function EditLeadSource({ leadSource }) {
  // console.log(leadSource);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <EditIcon />
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit Lead Source</DialogTitle>

        <Formik
          initialValues={{
            id: leadSource.id,
            name: leadSource.name,
            active: leadSource.active,
            updated_reason: "Edited Lead Source",
            api_flag: leadSource.api === "" ? false : true,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            // console.log(values);
            dispatch(editLeadSource(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <TextfieldWrapper label="Lead Source Name" name="name" />
                {
                  // add check box for api_flag here, default is false
                }
                <CheckboxWrapper label="API Source?" name="api_flag" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
