import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { createQuestion } from "../../State/SurveysDuck";

export default function CreateQuestions({ id, questions }) {
  // console.log(questions);
  const [open, setOpen] = React.useState(false);
  const [showDependencies, setShowDependencies] = React.useState(false);
  const dispatch = useDispatch();
  const [dependentQuestion, setDependentQuestion] = React.useState({});

  const handleToggleDependencies = () => {
    setShowDependencies(!showDependencies);
  };

  const formik = useFormik({
    initialValues: {
      survey_campaign_id: id,
      question: "",
      question_type: "",
      question_dependencies: {},
    },
    validationSchema: Yup.object({
      question: Yup.string().required("A Question is required"),
      question_type: Yup.string().required("A Question Type is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values);
      dispatch(createQuestion(values));
      resetForm();
      setTimeout(() => {
        handleClose();
      }, 500);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add Question
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Stack sx={{ p: 4 }}>
              <Typography variant="h4" align="center" my={4}>
                Create Question
              </Typography>

              <TextField
                margin="normal"
                label="Question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && formik.errors.question
                    ? true
                    : false
                }
                helperText={formik.touched.question && formik.errors.question}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel id="select_type">Select Question Type</InputLabel>
                <Select
                  labelId="select_type"
                  id="select_question_type"
                  label="Select Question Type"
                  name="question_type"
                  value={formik.values.question_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.question_type && formik.errors.question_type
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.question_type && formik.errors.question_type
                  }
                >
                  <MenuItem value="radio"> Multi Select</MenuItem>
                  <MenuItem value="slider">Slider (1- 10)</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDependencies}
                    onChange={handleToggleDependencies}
                    color="primary"
                  />
                }
                label="Link to other Question"
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              />
              {questions && showDependencies && (
                <>
                  <InputLabel id="select_type">
                    Select Existing Question
                  </InputLabel>
                  <Select
                    labelId="select_question_id"
                    id="select_question_id"
                    label="Select Question"
                    name="existing_question_id"
                    value={formik.values.question_dependencies?.question_id}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "question_dependencies",
                        {
                          ...formik.values.question_dependencies,
                          question_id: e.target.value,
                        },
                        true,
                      );
                      setDependentQuestion(
                        questions.find(
                          (question) => question.id === e.target.value,
                        ),
                      );
                    }}
                  >
                    {questions
                      .filter((question) => question.question_type === "radio")
                      .map((question) => (
                        <MenuItem key={question.id} value={question.id}>
                          {question.question}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.values.question_dependencies?.question_id &&
                    dependentQuestion && (
                      <>
                        <InputLabel id="select_answer_set">
                          Select Answer Set
                        </InputLabel>
                        <Select
                          labelId="select_answer_id"
                          id="select_answer_id"
                          label="Select Answer Set"
                          name="answer_id"
                          value={formik.values.question_dependencies?.answer_id}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "question_dependencies",
                              {
                                ...formik.values.question_dependencies,
                                answer_id: e.target.value,
                              },
                              true,
                            );
                          }}
                        >
                          {dependentQuestion.survey_answers.map((answerSet) => (
                            <MenuItem key={answerSet.id} value={answerSet.id}>
                              {answerSet.answer}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                </>
              )}
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Stack>
          </>
        </form>
      </Dialog>
    </div>
  );
}
