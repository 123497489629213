import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { editSurveyQuestion } from "../../State/SurveysDuck";

export default function EditQuestion({ question, questions }) {
  // console.log("question", question);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [showDependencies, setShowDependencies] = React.useState(false);
  const [dependentQuestion, setDependentQuestion] = React.useState(null);

  const handleToggleDependencies = () => {
    setShowDependencies(!showDependencies);
    // console.log("showDependencies", !showDependencies);
  };

  const [values, setValues] = React.useState({
    question: "",
    question_type: "",
    question_dependencies: {},
  });

  useEffect(() => {
    setValues({
      question: question?.question,
      question_type: question?.question_type,
      question_dependencies: question?.question_dependencies,
    });

    if (question?.question_dependencies) {
      setShowDependencies(true);
    }

    if (question?.question_dependencies?.question_id) {
      setDependentQuestion(
        questions.find(
          (q) => q.id === question?.question_dependencies?.question_id,
        ),
      );

      // console.log("dependentQuestion", dependentQuestion);
    }
  }, [
    question,
    setShowDependencies,
    setDependentQuestion,
    questions,
    dependentQuestion,
  ]);

  const formik = useFormik({
    initialValues: values,
    validationSchema: Yup.object({
      question: Yup.string().required("A Question is required"),
      question_type: Yup.string().required("A Question Type is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("values", values);
      // console.log("showDependencies", showDependencies);
      const newValues = showDependencies
        ? values
        : { ...values, question_dependencies: null };
      dispatch(editSurveyQuestion(question.id, newValues));
      setTimeout(() => {
        handleClose();
      }, 500);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <div>
      <Tooltip title={"Edit Question"}>
        <IconButton onClick={handleClickOpen} color="secondary" edge="end">
          <EditTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Stack sx={{ p: 4 }}>
              <Typography variant="h6" align="center" my={1}>
                Edit Question
              </Typography>

              <TextField
                margin="normal"
                label="Question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && formik.errors.question
                    ? true
                    : false
                }
                helperText={formik.touched.question && formik.errors.question}
              />

              <FormControl fullWidth>
                <InputLabel id="select_type">Select Question Type</InputLabel>
                <Select
                  labelId="select_type"
                  id="select_question_type"
                  label="Select Question Type"
                  name="question_type"
                  value={formik.values.question_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.question_type && formik.errors.question_type
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.question_type && formik.errors.question_type
                  }
                >
                  <MenuItem value="radio"> Multi Select</MenuItem>
                  <MenuItem value="textfield">Text Input</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDependencies}
                    onChange={handleToggleDependencies}
                    color="primary"
                  />
                }
                label="Link to other Question"
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              />
              {questions && showDependencies && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="select_existing_question">
                      Select Existing Question
                    </InputLabel>
                    <Select
                      labelId="select_existing_question"
                      id="select_existing_question_id"
                      label="Select Existing Question"
                      name="existing_question_id"
                      value={formik.values.question_dependencies?.question_id}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "question_dependencies",
                          {
                            ...formik.values.question_dependencies,
                            question_id: e.target.value,
                          },
                          true,
                        );
                        setDependentQuestion(
                          questions.find(
                            (question) => question.id === e.target.value,
                          ),
                        );
                      }}
                    >
                      {questions
                        .filter((q) => q.question_type === "radio")
                        .map((q) => (
                          <MenuItem key={q.id} value={q.id}>
                            {q.question}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.values.question_dependencies?.question_id &&
                    dependentQuestion && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="select_answer_id">
                            Select Answer
                          </InputLabel>
                          <Select
                            labelId="select_answer_id"
                            id="select_answer_id"
                            label="Select Answer"
                            name="answer_id"
                            value={
                              formik.values.question_dependencies?.answer_id
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "question_dependencies",
                                {
                                  ...formik.values.question_dependencies,
                                  answer_id: e.target.value,
                                },
                                true,
                              );
                            }}
                          >
                            {dependentQuestion.survey_answers.map(
                              (answerSet) => (
                                <MenuItem
                                  key={answerSet.id}
                                  value={answerSet.id}
                                >
                                  {answerSet.answer}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      </>
                    )}
                </>
              )}
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Stack>
          </>
        </form>
      </Dialog>
    </div>
  );
}
