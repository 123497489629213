import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCampaignLeadSourcesByCampaignId,
  RESET_CAMPAIGN_LEAD_SOURCES,
} from "./State/CampaignLeadSourceDuck";
import AddNewCampaignLeadSource from "./Components/LeadSources/AddNewCampaignLeadSource";
import { Stack, LinearProgress, Typography } from "@mui/material";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import EditCampaignLeadSource from "./Components/LeadSources/EditCampaignLeadSource";

const CampaignLeadSources = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const LeadSources = useSelector((state) => state.campaignLeadSourceReducer);
  const {
    addLeadSourceSuccess,
    success,
    campaignLeadSources,
    loading,
    editLeadSourceSuccess,
  } = LeadSources;

  // console.log(campaignLeadSources, "campaignLeadSources");

  useEffect(() => {
    dispatch(getCampaignLeadSourcesByCampaignId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (addLeadSourceSuccess || editLeadSourceSuccess) {
      dispatch(getCampaignLeadSourcesByCampaignId(id));
      dispatch({ type: RESET_CAMPAIGN_LEAD_SOURCES });
    }
  }, [dispatch, addLeadSourceSuccess, id, editLeadSourceSuccess]);

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Source", width: 250 },
    {
      field: "start_date",
      headerName: "From",
      width: 200,
    },
    {
      field: "end_date",
      headerName: "To",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Stack direction="row">
          <EditCampaignLeadSource
            id={params.row.campaign_id}
            campaignLeadSources={params.row}
          />
        </Stack>
      ),
    },
  ];

  // console.log(campaignLeadSources, "campaignLeadSources");

  const rows =
    success && campaignLeadSources && campaignLeadSources?.length > 0
      ? campaignLeadSources?.map((leadSource) => {
          return {
            id: leadSource.id,
            campaign_id: leadSource.campaign_id,
            name: leadSource.LeadSource.name,
            start_date: leadSource.start_date,
            end_date: leadSource.end_date,
          };
        })
      : [];

  return success ? (
    <>
      <div>
        <Stack sx={{ my: 3 }}>
          <AddNewCampaignLeadSource
            id={id}
            campaignLeadSources={campaignLeadSources}
          />
          {
            // link to https://api.cdasolutions.co.za/docs/
          }
          <Typography variant="body2" color="text.secondary">
            Any sources indicated below will only be added to the campaign
            between the "From" and "To" dates.{" "}
            <a
              href="https://api.cdasolutions.co.za/docs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              API Documentation
            </a>
          </Typography>
        </Stack>
        {loading && <LinearProgress />}
        {campaignLeadSources && campaignLeadSources.length > 0 ? (
          <div style={{ height: "50vh", width: "100%" }}>
            <DataGridPremium
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
            />
          </div>
        ) : (
          <div>No API sources linked</div>
        )}
      </div>
      <AlertPopup
        open={addLeadSourceSuccess}
        severity="success"
        message="Added Successfully"
      />
      <AlertPopup
        open={editLeadSourceSuccess}
        severity="success"
        message="Updated Successfully"
      />
    </>
  ) : (
    <div>Unable to process request...</div>
  );
};

export default CampaignLeadSources;
